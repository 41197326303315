import React, { FC, useMemo } from 'react';

type InputStatus = 'default' | 'error' | 'success';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  helperText?: string;
  rightIcon?: React.ReactNode;
  status?: InputStatus;
}

const getBorderColorClass = (status: InputStatus) => {
  switch (status) {
    case 'error': {
      return 'border-red-primary focus:border-red-light-3';
    }
    case 'success': {
      return 'border-green-primary focus:border-green-light-3';
    }
    case 'default': {
      return 'border-normal focus:border-blue-light-3 hover:border-blue-primary';
    }
  }
};

const getTextColorClass = (status: InputStatus, isDisabled: boolean) => {
  if (isDisabled) {
    return 'text-dark-5';
  }
  switch (status) {
    case 'error': {
      return 'text-red-primary';
    }
    case 'success': {
      return 'text-green-primary';
    }
    case 'default': {
      return 'text-dark-4';
    }
  }
};

const Input: FC<Props> = ({
  placeholder,
  type,
  value,
  onChange,
  label,
  status = 'default',
  helperText,
  rightIcon,
  disabled = false,
  className
}) => {
  const renderInput = useMemo(() => {
    const borderColorClass = !disabled && getBorderColorClass(status);
    const textColorClass = !disabled && getTextColorClass(status, disabled);
    const bgClass = disabled ? 'bg-gray-2' : '';
    const cursorClass = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
    return (
      <div className={`relative flex items-center`}>
        <input
          className={`w-full p-3 text-base ease-in duration-100 rounded-lg outline-none h-[46px] border-[1px] focus:border-[3px] ${borderColorClass}  ${bgClass} ${cursorClass} ${rightIcon && 'pr-10'}`}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {rightIcon && (
          <div
            className={`absolute right-3 cursor-pointer z-10 input-suffix ${textColorClass} ${cursorClass}`}>
            {rightIcon}
          </div>
        )}
      </div>
    );
  }, [status, value, rightIcon, onChange, type, placeholder, disabled]);

  const renderhelperText = useMemo(() => {
    if (!helperText) {
      return null;
    }
    const textColorClass = disabled ? 'text-dark-5' : getTextColorClass(status, disabled);
    return (
      <div
        className={`text-sm font-normal leading-[22px] absolute bottom-[-25px] ${textColorClass}`}>
        {helperText}
      </div>
    );
  }, [helperText, status, disabled]);

  return (
    <div className={`relative ${className}`}>
      {label && (
        <div
          className={`text-dark text-base leading-6 font-medium mb-[10px] ${disabled && 'text-dark-5'}`}>
          {label}
        </div>
      )}
      {renderInput}
      {renderhelperText}
    </div>
  );
};

export default Input;
