
import PasswordInput from '../common/PasswordInput';
import Input from '../common/Input';
import { ArrowRight } from 'lucide-react';

const LoginForm = () => {
  return (
    <div className="flex flex-col w-full">
      <Input placeholder="Email" type="email" className='mb-4' />
      <PasswordInput />
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-2 text-primary text-sm">
          <input
            type="checkbox"
            className="border-inputBorder w-[20px] h-[20px] rounded border-[1px]"
          />
          Remember Me
        </div>
        <div className="font-medium text-sm cursor-pointer">Forgot Password ?</div>
      </div>
      <button className="flex items-center justify-center w-full p-4 gap-2 bg-black text-white rounded-xl mt-4 mb-2">
        Sign In <ArrowRight />
      </button>
    </div>
  );
};

export default LoginForm;
