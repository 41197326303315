import Input from './Input';

function PasswordInput() {
  return (
    <div className="w-full">
      <div className="relative">
        <Input type="password" placeholder="Password" />
      </div>
    </div>
  );
}

export default PasswordInput;
