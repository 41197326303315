import LoginForm from '../components/Login/LoginForm';
const LoginPage = () => {
  return (
    <div className="container mx-auto h-[100vh] flex justify-center">
      <div className="flex flex-col justify-center items-center h-full max-w-[432px]">
        <div className="flex flex-col items-center mb-8">
          <img className="h-[70px] w-fit" src="/assets/images/logo.png" alt="Logo" />
          <div className="text-[40px] mt-6 mb-2 leading-[50px] font-semibold">Process Bee</div>
          <div className="text-sm text-sub text-center">
            Placeholder Placeholder Placeholder Placeholder Placeholder Placeholder{' '}
          </div>
        </div>
        <LoginForm />
        <div>
          <div className="text-center">
            <div className="text-primary text-sm">
              Don't have an account?{' '}
              <span className="text-black underline">Contact Process Be</span>
            </div>
          </div>
          <div className="mt-2 text-[10px] text-primary text-center">
            By continuing, you agree to Process Bee’s{' '}
            <span className="underline">Terms of Service </span> and{' '}
            <span className="underline">Privacy Policy</span>.
          </div>
          <div className="mt-8">
            <p className="text-sm text-body text-center">Trusted by teams at</p>
            <div className="flex mt-2 items-center justify-between">
              <img src="/assets/images/headspace.png" alt="logo" />
              <img src="/assets/images/shopify.png" alt="logo" />
              <img src="/assets/images/revolut.png" alt="logo" />
              <img src="/assets/images/duolingo.png" alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
